import {
  Container,
  CssBaseline,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Navi from "../component/Navi";
import { openings } from "./Career";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  heading: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  anchor: {
    display: "flex",
    gap: 8,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
const Job = () => {
  const classes = useStyles();
  const { id } = useParams();
  const selected = openings.find((x) => x.id === id);
  return (
    <>
      <Helmet>
        <title>ESSL - Vacancies</title>
        <meta
          name="description"
          content="Welcome to Everlasting Systems and Solutions LLC (ESSL) careers"
        ></meta>
        <meta
          name="keywords"
          content="website development in Cypress, website development in Houston,website development in Texas,website development near Cypress, website development near 77429, mobile app development near Cypress, android app development near Cypress, ios app development near Cypress, software development, custom software, software solutions, software company, software development company, software developer, software development services, software development agency, software development firm, software development company, software development company, software development company, software development company, software development company, software development company, software development company, software development company,vacancies,tech jobs, ESSL"
        />
      </Helmet>
      <CssBaseline />
      <Navi navType="dark" />
      <Container maxWidth="lg">
        <main className="mt-5 main-section-nobg parallax">
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h4" gutterBottom>
              Job Title: {selected?.title}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Job Location: 100% Remote
            </Typography>
            <Typography variant="body1">{selected?.description}</Typography>
            <div>
              {selected?.responsibilities?.length > 0 && (
                <Typography variant="h6" className={classes.heading}>
                  Your Responsibilities
                </Typography>
              )}
              {selected?.responsibilities.map((r, index) => (
                <Typography
                  variant="subtitle1"
                  className={classes.heading}
                  key={index}
                >
                  <span style={{ fontWeight: "bold" }}>{r.title}:</span>{" "}
                  {r.description}
                </Typography>
              ))}
            </div>
            <div>
              {selected?.expectations?.length > 0 && (
                <Typography variant="h6" className={classes.heading}>
                  Our Expectations
                </Typography>
              )}
              {selected?.expectations?.map((e) => (
                <Typography
                  key={e}
                  variant="subtitle1"
                  className={classes.heading}
                >
                  {e}
                </Typography>
              ))}
            </div>

            <Typography variant="body2" color="textSecondary">
              Posted on: August 1, 2023
            </Typography>
            <div className={classes.anchor}>
              <a
                href={`/careers`}
                className="text-decoration-none badge p-2 bg-primary text-wrap text-white"
              >
                Back
              </a>
              {selected && (
                <a
                  href={`/careers/apply?jobType=${selected.jobType}`}
                  className="text-decoration-none badge text-wrap p-2 badge-color btn-custom"
                >
                  Apply
                </a>
              )}
            </div>
          </Paper>
        </main>
      </Container>
    </>
  );
};

export default Job;
